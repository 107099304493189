import React from 'react'
import { usePage } from '@/hooks'
import Link from 'next/link'
import { Grid } from '@material-ui/core'

import './footer.less'

// state初始值不要用let的变量值
const init: Record<string, any> = {
  // 与页面无关的变量写这里
  // vari: 'xxx',
  // 与页面有关的变量写这里
  // state: {form: {}},
  state: {},
  async: {},
}
function Index (): JSX.Element {

  /**
   * state: init中的state
   * events: init中所有的方法
   */
  // @ts-ignore
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [state, events, loading, error] = usePage(init)
  // 函数式组件内只能写hooks代码，hooks的回调必须直接传入events.xxx方法。eg: useMount(events.xxx)
  // 函数式组件内允许写state, events, loading, error, props的结构

  return (
    <div className="components-footer-container">
      <footer className="footer-bar hidden-xs">
        <div className="footer-container">
          <div className="top-container">
            <div className="left">
              <div className="left-top-container">
                <Grid container>
                  <Grid item xs={4}>
                    <div className="icon">
                      <img src="//img3.dian.so/lhc/2021/09/16/294w_42h_9EE971631757863.png" />
                    </div>
                  </Grid>
                  <Grid item xs={8}>
                    <div className="item">
                      <Grid container>
                        <Grid item xs={4}>
                          <div><Link href="/os/product"><span className="title">产品中心</span></Link></div>
                          <div><Link href="/os/hot"><span className="title">新闻中心</span></Link></div>
                        </Grid>

                        <Grid item xs={4}>
                          <div><span className="title">申请合作</span></div>
                          <Link href="/os/merchant"><div className="sub-title"><span />商户合作</div></Link>
                          <Link href="/os/agentRecruit"><div className="sub-title"><span />代理加盟</div></Link>
                          <Link href="/os/cooperation"><div className="sub-title"><span />广告投放</div></Link>
                        </Grid>

                        <Grid item xs={4}>
                          <div><span className="title">关于小电</span></div>
                          <Link href="/os/about?tab=0"><div className="sub-title"><span />关于我们</div></Link>
                          <Link href="/os/about?tab=1"><div className="sub-title"><span />荣誉奖项</div></Link>
                          <Link href="/os/about?tab=2"><div className="sub-title"><span />合作伙伴</div></Link>
                          <Link href="/os/joinUs"><div className="sub-title"><span />加入我们</div></Link>
                        </Grid>
                      </Grid>
                    </div>
                  </Grid>

                </Grid>

              </div>

              <div className="left-bottom-container">
                <div style={{ float: 'right', marginTop: '20px' }}>
                  <p className="address">公司地址：杭州市余杭区文一西路998号浙江海外高层次人才创新园5幢705号</p>
                  <p className="phone">
                    <span>消费者服务：400-0011-066</span>
                  </p>
                  <div className="time">周一至周日 09:00-24:00</div>
                </div>
              </div>

            </div>

            <div className="right">
              <div className="qrcode-container">
                <div className="weibo">
                  <img className="icon" src="//img3.dian.so/lhc/2021/11/03/58w_48h_0549F1635927545.png" />
                  <div className="qrcode weibo-img">
                    <img src="//img3.dian.so/lhc/2021/11/11/114w_114h_C56201636611193.jpg" />
                  </div>
                </div>

                <div className="alipay">
                  <img className="icon" src="//img3.dian.so/lhc/2021/11/03/60w_60h_83FA01635927566.png" />
                  <div className="qrcode alipay-img">
                    <img src="//img3.dian.so/lhc/2019/03/24/411w_412h_B51051553430539.jpg" />
                  </div>
                </div>

                <div className="wx">
                  <img className="icon" src="//img3.dian.so/lhc/2021/11/03/76w_74h_E11DE1635927592.png" />
                  <div className="qrcode wx-img">
                    <img src="//img3.dian.so/lhc/2019/03/24/411w_412h_F0D361553430424.png" />
                  </div>
                </div>
              </div>
              <div className="emails-contrl">
                <div className="">品牌合作：aiyou@dian.so</div>
                <div className="">媒体联络：xuanren@dian.so</div>
                <div className="">廉政举报：lianzheng@dian.so</div>
              </div>
            </div>
          </div>

          <div className="bottom-container">
            <Link href="https://beian.miit.gov.cn">
              <a target="_blank" className="corporation">杭州有电科技有限公司 2016-2019@版权所有 浙ICP备 15027995号-5</a>
            </Link>
          </div>

        </div>

      </footer>

      <footer className="foot-bar-xs visible-xs">
        <div className="foot-bar-container">
          <div className="foot-bar-left">
            <img className="foot-bar-left-logo" src="//img3.dian.so/lhc/2021/09/16/334w_54h_1DF111631758130.png" />
            <div className="foot-bar-left-qrcode">
              <Grid container>
                <Grid item xs={4}>
                  <img src="//img3.dian.so/lhc/2021/11/11/114w_114h_C56201636611193.jpg" />
                  <p>微博</p>

                </Grid>
                <Grid item xs={4}>
                  <img src="//img3.dian.so/lhc/2019/03/24/411w_412h_F0D361553430424.png" />
                  <p>微信公众号</p>
                </Grid>

                <Grid item xs={4}>
                  <img src="//img3.dian.so/lhc/2019/03/24/411w_412h_B51051553430539.jpg" />
                  <p>支付宝生活号</p>
                </Grid>
              </Grid>
            </div>
            <div className="foot-bar-phone">
              <a href="tel:400-0011-066" className="foot-bar-right-cs">消费者服务：400 0011 066</a>
              <div className="foot-bar-email">周一至周日 09:00-24:00</div>
              <div className="foot-bar-phone-emails">
                <div className="line" />
                <div className="emails-contrl">
                  <div>品牌合作：aiyou@dian.so</div>
                  <div>媒体联络：xuanren@dian.so</div>
                  <div>廉政举报：lianzheng@dian.so</div>
                </div>
              </div>
            </div>
            <p className="foot-bar-email" style={{ fontSize: '9px', paddingTop: '43px', color: 'rgba(255, 255, 255, 0.45)' }}>公司地址：杭州市余杭区文一西路998号浙江海外高层次人才创新园5幢705号</p>
            <Link href="https://beian.miit.gov.cn">
              <a target="_blank" className="foot-bar-left-corporation" style={{ fontSize: '9px', color: '#FFFFFF' }}>杭州小电科技股份有限公司<br />2016-2019@版权所有 浙ICP备 15027995号-5</a>
            </Link>
          </div>
        </div>
      </footer>
    </div>
  )
}

export default Index
