import Link from 'next/link'
import { usePage } from '@/hooks'
import './nav.less'

const init: Record<string, any> = {
  state: {
    isColor: '',
    hover: false,
    orActive: false,
    currentRouter: false,
    hash: '',
  },
  downButton () {
    this.setState({
      hover: !this.state.hover,
    })
  },
  barActive () {
    this.setState({
      orActive: !this.state.orActive,
    })
  },
  onLoad () {
    this.setState({
      hash: location.pathname.split('/os/')[1],
    })
  },
  goPage (url: string) {
    window.location.href = url
  },
}
function Header (): JSX.Element {
  const [state, events] = usePage(init)
  const { hover, orActive, hash } = state
  const { downButton, barActive, goPage } = events

  return <div className="component-nav-container clearfix">
    <header id="headerBar" className="head-bar hidden-xs">
      <div className="container">
        <img src="//img3.dian.so/lhc/2021/09/16/294w_42h_9EE971631757863.png" />
        <ul>
          <li className={`${hash === '' ? 'container-li active' : 'container-li'}`}><Link href="/"><a>首页</a></Link></li>
          <li className={`${hash === 'product' ? 'container-li active' : 'container-li'}`}><Link href="/os/product"><a>产品中心</a></Link></li>
          <li className={`${hash === 'hot' ? 'container-li active' : 'container-li'}`}><Link href="/os/hot"><a>公司新闻</a></Link></li>
          <li className={`${hash === 'merchant' || hash === 'agent' || hash === 'cooperation' || hash === 'agentRecruit' ? 'container-li active' : 'container-li'}`} id="applyop">
            <a onClick={downButton}><span style={{ cursor: 'pointer' }}>申请合作</span>
              {hover && <div className="applyop-ul">
                <div className="title">
                  <div>申请合作</div>
                </div>
                <div className="sub-nav">
                  <div onClick={(): void => { goPage('/os/merchant') }}>商户合作</div>
                  <div onClick={(): void => { goPage('/os/agentRecruit') }}>代理加盟</div>
                  <div onClick={(): void => { goPage('/os/cooperation') }}>广告投放</div>
                </div>
                <div className="img">
                  <img className="pop-bg" src="//img3.dian.so/lhc/2021/11/01/604w_378h_A58E61635756341.jpeg" />
                </div>
              </div>}
            </a>
          </li>
          <li><Link href="https://www.xiaodian.so"><a>信息发布</a></Link></li>
          <li className={`${hash === 'about' ? 'container-li active' : 'container-li'}`}><Link href="/os/about"><a>关于我们</a></Link></li>
          <li className={`${hash === 'joinUs' ? 'container-li active' : 'container-li'}`}><Link href="/os/joinUs"><a>加入我们</a></Link></li>
        </ul>
      </div>
    </header>
    {/* 移动端适配 */}
    <header className="head-bar-xs visible-xs">
      <img src="//img3.dian.so/lhc/2021/09/16/294w_42h_9EE971631757863.png" />
      <img src="//img3.dian.so/lhc/2018/12/19/36w_28h_D6B8C1545204602.png" id="button-bar" onClick={barActive} className={orActive ? 'bar-active' : ''} />
      <div className={orActive ? 'side-bar bar-active' : 'side-bar'} id="back-bar">
        <div className="bar-list" id="right-bar">
          <ul>
            <li className={`${hash === '' ? 'container-li active' : 'container-li'}`}><Link href="/"><a>首页</a></Link></li>
            <li className={`${hash === 'product' ? 'container-li active' : 'container-li'}`}><Link href="/os/product"><a>产品中心</a></Link></li>
            <li className={`${hash === 'merchant' || hash === 'agent' || hash === 'cooperation' ? 'container-li active' : 'container-li'}`} id="applyop"><a onMouseEnter={downButton}>申请合作<span className="down-button" /></a>
              <ul className={!hover ? 'applyop-ul' : 'applyop-ul head-apply-active'}>
                <li className={`${hash === 'agentRecruit' ? 'container-li active' : 'container-li'}`}><Link href="/os/agentRecruit"><a>代理加盟</a></Link></li>
                <li className={`${hash === 'merchant' ? 'container-li active' : 'container-li'}`}><Link href="/os/merchant"><a>商户合作</a></Link></li>
                <li className={`${hash === 'cooperation' ? 'container-li active' : 'container-li'}`}><Link href="/os/cooperation"><a>广告合作</a></Link></li>
              </ul>
            </li>
            <li className={`${hash === 'hot' ? 'container-li active' : 'container-li'}`}><Link href="/os/hot"><a>公司新闻</a></Link></li>
            <li><Link href="https://www.xiaodian.so"><a>信息发布</a></Link></li>
            <li className={`${hash === 'about' ? 'container-li active' : 'container-li'}`}><Link href="/os/about"><a>关于我们</a></Link></li>
            <li className={`${hash === 'joinUs' ? 'container-li active' : 'container-li'}`}><Link href="/os/joinUs"><a>加入我们</a></Link></li>

          </ul>
        </div>
      </div>
    </header>

  </div>
}

export default Header
